<template>
  <div id="app">
    <van-tabs v-model="active" sticky>
      <van-tab title="待处理">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            v-if="data.length > 0"
            style="padding-top: 3vw; padding-left: 3vw; padding-right: 3vw;"
        >
          <div v-for="item in data" :key="item.index"
               style="margin-bottom: 12px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12);"
               @click="routeToAlarmDetail(item)">
            <div style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px;">
              <div style="display: flex ;background: #FFEBDA; height: 38px; border-radius: 7px 7px 0px 0px;">
                <span style="color: #FF6666; line-height: 38px; margin-left: 12px;">{{
                    handleAlarmType(item.alarmType)
                  }}</span>
                <span style="flex: 1; text-align: right; color: #FF6666; line-height: 38px; margin-right: 12px;">{{
                    item.state === 'PENDING' ? '待处理' : '处理中'
                  }}</span>
              </div>
              <div style="display: flex; margin-top: 12px; margin-bottom: 12px">
                <span style="flex: 1; color: #333333; font-size: 14px; margin-left: 12px; line-height: 14px;"><span
                    style="color: #999999; margin-right: 4px;">
                    发生时间:
                  </span>{{ item.createdTime | moment('yyyy-MM-DD HH:mm:ss') }}</span>

                <span
                    style="color: #333333; margin-right: 12px; align-self: center; font-size: 14px; line-height: 14px;">></span>
              </div>
            </div>
          </div>
        </van-list>
        <div v-if="data.length === 0 && !loading"
             style="display: grid; justify-items: center; position:fixed; top: 50%; left: 50%; transform: translate(-50%, -50%)">
          <img
              style="width: 64px; height: 62px; "
              :src="require('../../../src/assets/images/kitchen/icon_none.png')"
              alt=""/>
          <span style="font-size: 18px; color: #34AC40; margin-top: 10px;">暂无待处理的报警</span>
        </div>
      </van-tab>
      <van-tab title="已处理">
        <van-list
            v-model="solvedLoading"
            :finished="solvedFinished"
            finished-text="没有更多了"
            @load="onProcessedLoad"
            v-if="solvedData.length > 0"
            style="padding-top: 3vw; padding-left: 3vw; padding-right: 3vw;"
        >
          <div v-for="item in solvedData" :key="item.index"
               style="margin-bottom: 12px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12);"
          >
            <div style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px;"
                 @click="routeToAlarmDetail(item)">
              <div style="background: #D9FFDD; height: 38px; border-radius: 7px 7px 0px 0px;">
                <span style="color: #34AC40; line-height: 38px; margin-left: 12px;">{{
                    handleAlarmType(item.alarmType)
                  }}</span>
              </div>

              <div style="display: flex;">
                <div style="flex: 1; display: grid; margin-top: 12px; margin-bottom: 12px;">
                  <span style="color: #333333; font-size: 14px; margin-left: 12px; line-height: 14px;"><span
                      style="color: #999999; margin-right: 4px;">
                    发生时间:
                  </span>{{ item.createdTime | moment('yyyy-MM-DD HH:mm:ss') }}</span>
                  <span style="color: #333333; margin-left: 12px; line-height: 14px; margin-top: 16px;"><span
                      style="color: #999999; margin-right: 4px;">
                    处理时间:
                  </span>{{ item.handleTime | moment('yyyy-MM-DD HH:mm:ss') }}</span>
                  <span style="color: #333333; margin-left: 12px; line-height: 14px; margin-top: 16px;"><span
                      style="color: #999999; margin-right: 4px;">
                    处理人:
                  </span>{{
                      item ? item.handleUser.name : '-'
                    }}</span>
                </div>

                <span style="color: #333333; margin-right: 12px; align-self: center;">></span>

              </div>

            </div>

          </div>
        </van-list>
        <div v-if="solvedData.length === 0"
             style="display: grid; justify-items: center; position:fixed; top: 50%; left: 50%; transform: translate(-50%, -50%)">
          <img
              style="width: 64px; height: 62px; "
              :src="require('../../../src/assets/images/kitchen/icon_none.png')"
              alt=""/>
          <span style="font-size: 18px; color: #34AC40; margin-top: 10px;">暂无已处理的报警</span>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import {KITCHEN_BUSINESS_ID} from "../../store/mutation-types";
import {post} from "../../utils/httpKitchen";

export default {
  name: "AlarmList",
  data() {
    return {
      active: 0,
      loading: false,
      finished: false,
      data: [],
      load: {
        page: 0,
        size: 20,
      },
      solvedLoading: false,
      solvedFinished: false,
      solvedData: [],
      solvedLoad: {
        page: 0,
        size: 20,
      },
    }
  },
  mounted() {
    this.onLoad();
    this.onProcessedLoad();
  },
  methods: {
    onLoad() {
      this.loading = true;
      post('api/kitchen/app/v1/alarm/search', {
        page: this.load.page,
        size: this.load.size,
        filters: [
          {
            name: 'state',
            op: 'in',
            value: [
              'PENDING',
              'PROCESSING'
            ]
          }
        ],
        sort: 'createdTime DESC'
      })
          .then(res => {
            this.load.page = this.load.page + 1;

            this.data = this.data.concat(res.data.rows);

            // 加载状态结束
            this.loading = false;

            // 数据全部加载完成
            if (this.data.length >= res.data.total) {
              this.finished = true;
            }
          })
          .catch(err => {

          });
    },
    onProcessedLoad() {
      this.solvedLoading = true;
      post('api/kitchen/app/v1/alarm/search', {
        page: this.load.page,
        size: this.load.size,
        filters: [
          {
            name: 'state',
            op: '=',
            value: [
              'SOLVED'
            ]
          }
        ],
        sort: 'createdTime DESC'
      })
          .then(res => {
            this.solvedLoad.page = this.solvedLoad.page + 1;

            this.solvedData = this.solvedData.concat(res.data.rows);

            // 加载状态结束
            this.solvedLoading = false;

            // 数据全部加载完成
            if (this.solvedData.length >= res.data.total) {
              this.solvedFinished = true;
            }
          })
          .catch(err => {

          });
    },
    routeToAlarmDetail(value) {
      this.$router.push({name: "kitchenAlarmDetail", query: {id: value.id}});
    },
    handleAlarmType(alarmType) {
      switch (alarmType.toString()) {
        case "6":
          return "违规打电话";
        case "7":
          return "违规吸烟";
        case "31":
          return "未佩戴厨师帽";
        case "32":
          return "鼠患检测";
        case "33":
          return "未佩戴口罩";
        case "34":
          return "未穿厨师服";
      }
    },
  }
}
</script>

<style scoped>

</style>